<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0 pt-0 pb-0 px-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" class="pb-3" />
        <v-card class="mx-auto my-12 mt-2 mb-2" max-width="1200" outlined>
          <v-container class="pr-4">
            <v-row>
              <v-col
                cols="12"
                md="10"
                class="py-0"
                align="left"
                @click="showFilters = true"
              >
                <FiltersSelected
                  :filters="filtersApplied"
                  v-if="!showFilters"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                align="right"
                align-self="center"
                class="py-0 pb-2"
              >
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                      class="primary--text"
                      @click.stop="showFilters = !showFilters"
                    >
                      {{ closeFiltersIcon }}
                    </v-icon>
                  </template>
                  <span>{{
                    !showFilters ? "Mostrar filtros" : "Ocultar filtros"
                  }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-expand-transition mode="in-out">
              <v-form
                v-show="showFilters"
                v-model="isFormValid"
                ref="filters-form"
                id="filters-form"
                dense
                @submit.prevent="applyFilters()"
              >
                <v-row>
                  <v-col cols="4" md="4" sm="4" class="py-0">
                    <v-autocomplete
                      v-model="tipoCoberturaSelected"
                      :items="itemsTipoCobertura"
                      label="Tipo de cobertura"
                      item-text="value"
                      item-value="id"
                      :rules="rules.required"
                      outlined
                      disabled
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" md="4" sm="4" class="pb-0 py-0">
                    <v-autocomplete
                      class="p-0"
                      v-model="convenioSelected"
                      :items="itemsConvenio"
                      label="Convenio"
                      return-object
                      item-text="value"
                      item-value="id"
                      @change="setPlanesByConvenios()"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" md="4" sm="4" class="pb-0 py-0">
                    <v-autocomplete
                      v-model="planSelected"
                      :loading="loadingPlanesByConvenio"
                      :items="itemsPlanes"
                      label="Plan"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4" class="pb-0 py-0">
                    <v-autocomplete
                      v-model="origenSelected"
                      :items="itemsOrigen"
                      label="Origen"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-text-field
                      dense
                      outlined
                      clearable
                      v-model="periodo"
                      label="Período"
                      hint="Formato AAAAMM"
                      persistent-hint
                      type="text"
                      :rules="rules.periodoYyyyMm"
                      v-mask="'######'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-autocomplete
                      v-model="grupoSelected"
                      :items="itemsGrupo"
                      label="Grupo de prácticas"
                      return-object
                      item-text="value"
                      item-value="id"
                      outlined
                      clearable
                      dense
                    >
                    </v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="3" class="pb-0 py-0">
                    <v-text-field
                      dense
                      outlined
                      v-model="codigo"
                      label="Código de práctica"
                      persistent-hint
                      type="text"
                      v-mask="'#########'"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="9" class="py-0 pb-0 py-0" align="end">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          size="28"
                          @click="resetForm"
                        >
                          {{ clearFiltersIcon }}
                        </v-icon>
                      </template>
                      <span>Limpiar filtros</span>
                    </v-tooltip>
                    <v-btn
                      class="to-right"
                      color="primary"
                      elevation="2"
                      small
                      :disabled="!isFormValid"
                      type="submit"
                      form="filters-form"
                    >
                      Aplicar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-expand-transition>
          </v-container>
        </v-card>
        <v-card>
          <v-data-table
            :headers="headers"
            :items="itemsCoseguros"
            height="100%"
            fixed-header
            class="elevation-1"
            :search="search"
            :loading="isLoading"
            :expanded.sync="expanded"
            show-expand
            item-key="tipoCobCoseId"
          >
            <template v-slot:top>
              <v-toolbar flat>
                <v-row>
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" v-if="canCreate">
                    <v-btn
                      color="primary"
                      @click="openModalEditCoseguro()"
                      class="to-right"
                    >
                      Nuevo
                    </v-btn>
                  </v-col>
                </v-row>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip left v-if="canEdit">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    class=""
                    @click="openModalEditCoseguro(item)"
                  >
                    {{ editIcon }}
                  </v-icon>
                </template>
                <span>Editar coseguro</span>
              </v-tooltip>
              <v-tooltip left v-if="canDelete && item.fPagoId != 1">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    @click="deleteCoseguro(item.tipoCobCoseId)"
                  >
                    {{ deleteIcon }}
                  </v-icon>
                </template>
                <span>Eliminar coseguro</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.data-table-expand`]="{ item, isExpanded }">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="handleExpansion(item, isExpanded)"
                    v-bind="attrs"
                    size="20"
                    v-on="on"
                    >{{
                      isExpanded ? "mdi-account-check" : "mdi-account"
                    }}</v-icon
                  >
                </template>
                <span>Usuarios</span>
              </v-tooltip>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <strong>Fecha alta:</strong>
                {{ item.fecAlta }}
                <strong style="padding-left:50px;">Usuario alta:</strong>
                {{ item.usuAlta }}
                <strong style="padding-left:50px;">Fecha modificación:</strong>
                {{ item.fecModi }}
                <strong style="padding-left:50px;"
                  >Usuario modificación:</strong
                >
                {{ item.usuModi }}
              </td>
            </template>
          </v-data-table>
        </v-card>
        <v-dialog
          v-if="openCosegurosEdit"
          v-model="openCosegurosEdit"
          max-width="65%"
          @keydown.esc="closeAndReload"
          persistent
        >
          <EditCosegurosTipoCoberturaEspecial
            :idTipoCob="tipoCoberturaSelected"
            :itemCoseguro="itemCoseguro"
            @closeAndReload="closeAndReload"
          ></EditCosegurosTipoCoberturaEspecial>
        </v-dialog>
        <DeleteDialog
          :titleProp="titleDelete"
          :maxWidth="'25%'"
          :isLoading="loadingBtnDelete"
          :openDelete.sync="showDeleteModal"
          @onDeleteItem="confirmDelete()"
        />
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-7 px-0"
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import FiltersSelected from "@/components/shared/FiltersSelected";
import { mask } from "vue-the-mask";
import DeleteDialog from "@/components/shared/DeleteDialog";
import PageHeader from "@/components/ui/PageHeader";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";
import EditCosegurosTipoCoberturaEspecial from "@/components/modules/afiliaciones/afiliados/EditCosegurosTipoCoberturaEspecial.vue";
import { setTimeout } from "timers";

export default {
  name: "CoseguroEnTipoCoberturaEspecial",
  components: {
    FiltersSelected,
    DeleteDialog,
    PageHeader,
    GoBackBtn,
    Ayuda,
    EditCosegurosTipoCoberturaEspecial
  },
  directives: { mask },
  data: vm => ({
    rules: rules,
    search: "",
    showFilters: true,
    showHelp: false,
    optionCode: enums.webSiteOptions.COSEGURO_TIPO_COBERTURA_ESPECIAL,
    showExpand: false,
    title: enums.titles.COSEGURO_TIPO_COBERTURA,
    closeFiltersIcon: enums.icons.CLOSE_FILTERS,
    clearFiltersIcon: enums.icons.CLEAR_FILTERS,
    deleteIcon: enums.icons.DELETE,
    editIcon: enums.icons.EDIT,
    searchIcon: enums.icons.SEARCH,
    filtersApplied: [],
    isFormValid: false,
    isLoading: false,
    canCreate: null,
    canDelete: null,
    canEdit: null,
    routeToGo: "TipoCoberturaEspecial",
    itemCoseguro: null,
    showDeleteModal: false,
    idToDelete: null,
    titleDelete: enums.titles.DELETE_COSEGURO_TIPO_COBERTURA,
    loadingBtnDelete: false,
    expanded: [],
    //filtros
    periodo: null,
    tipoCoberturaSelected: null,
    itemsTipoCobertura: null,
    itemsOrigen: [],
    origenSelected: null,
    planSelected: null,
    itemsPlanes: [],
    convenioSelected: null,
    itemsConvenio: [],
    itemsCoseguros: [],
    grupoSelected: null,
    itemsGrupo: [],
    codigo: null,
    headers: [
      {
        text: "Convenio",
        sortable: false,
        value: "os.value"
      },
      {
        text: "Plan",
        sortable: false,
        value: "plan.value"
      },
      {
        text: "Origen",
        sortable: false,
        value: "ori.value"
      },
      {
        text: "Grupo de prácticas",
        sortable: false,
        value: "grupo.value"
      },
      {
        text: "Área",
        sortable: false,
        value: "area",
        align: "center"
      },
      {
        text: "Modo",
        sortable: false,
        value: "modo",
        align: "center"
      },
      {
        text: "Aplicación",
        sortable: false,
        value: "operador",
        align: "center"
      },
      {
        text: "Valor",
        sortable: false,
        value: "valor",
        align: "right"
      },
      {
        text: "Cantidad",
        sortable: false,
        value: "cantidad",
        align: "right"
      },
      {
        text: "Tipo nomenclador",
        sortable: false,
        value: "nomenclador.value"
      },
      {
        text: "Código desde",
        sortable: false,
        value: "nomencladorCodigoDesde"
      },
      {
        text: "Código hasta",
        sortable: false,
        value: "nomencladorCodigoHasta"
      },
      {
        text: "Acciones",
        value: "actions",
        sortable: false,
        align: "start",
        width: "5%",
        class: "fixed-item-1"
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
        align: "start",
        class: "fixed-item-2",
        width: "1%"
      }
    ],
    openCosegurosEdit: false,
    loadingPlanesByConvenio: false,
    tipoCoberturaName: null
  }),
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.setPermisos();
    this.setPlanesByConvenios();
    this.setTipoCobertura();
    this.setSelects();
  },
  methods: {
    ...mapActions({
      fetchTiposCoberturas: "afiliaciones/fetchTiposCoberturas",
      getConvenios: "afiliaciones/getConvenios",
      getPlanesByConvenio: "configAfiliaciones/getPlanesByConvenio",
      fetchOrigenes: "configAppBenef/fetchOrigenes",
      getPlanesTaxonomy: "configAfiliaciones/getPlanesTaxonomy",
      getPermisosCoseguros: "afiliaciones/getPermisosCoseguros",
      getCoseguroTipoCoberturaEspecial:
        "afiliaciones/getCoseguroTipoCoberturaEspecial",
      getGrupos: "afiliaciones/getGrupos",
      deleteCoseguroTipoCob: "afiliaciones/deleteCoseguroTipoCob",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      // id = 1 -> corresponde a coseguros
      const gruposPractica = await this.getGrupos(1);
      this.itemsGrupo = gruposPractica;
      const tipoCoberturas = await this.fetchTiposCoberturas();
      this.itemsTipoCobertura = tipoCoberturas;
      const convenios = await this.getConvenios();
      this.itemsConvenio = convenios;
      const origenes = await this.fetchOrigenes();
      this.itemsOrigen = origenes;
    },
    async setPermisos() {
      const permisos = await this.getPermisosCoseguros(this.optionCode);
      this.canCreate = permisos.canCreate;
      this.canDelete = permisos.canDelete;
      this.canEdit = permisos.canEdit;
    },
    async setTipoCobertura() {
      if (this.$route.params.itemTipoCobertura) {
        this.tipoCoberturaSelected = this.$route.params.itemTipoCobertura.idTipocob;
        this.tipoCoberturaName = this.$route.params.itemTipoCobertura.tcobNombre;
        this.applyFilters();
      } else {
        this.$router.push({ name: "TipoCoberturaEspecial" });
      }
    },
    async setPlanesByConvenios() {
      if (this.convenioSelected == null || this.convenioSelected.id == 0) {
        this.itemsPlanes = [];
        const allPlanes = await this.getPlanesTaxonomy();
        this.itemsPlanes = allPlanes;
      } else {
        this.itemsPlanes = [];
        this.loadingPlanesByConvenio = true;
        const planes = await this.getPlanesByConvenio([
          this.convenioSelected.id
        ]);
        this.itemsPlanes = planes;
        this.loadingPlanesByConvenio = false;
      }
    },
    customizeFiltersApplied() {
      this.filtersApplied = [];
      if (this.tipoCoberturaSelected) {
        this.filtersApplied.splice(0, 1, {
          key: "tipoCoberturaSelected",
          label: "Tipo de cobertura",
          model: this.tipoCoberturaName
        });
      }
      if (this.convenioSelected) {
        this.filtersApplied.splice(1, 1, {
          key: "convenioSelected",
          label: "Convenio",
          model: this.convenioSelected.value
        });
      }
      if (this.planSelected) {
        this.filtersApplied.splice(2, 1, {
          key: "planSelected",
          label: "Plan",
          model: this.planSelected.value
        });
      }
      if (this.origenSelected) {
        this.filtersApplied.splice(3, 1, {
          key: "origenSelected",
          label: "Origen",
          model: this.origenSelected.value
        });
      }
      if (this.periodo) {
        this.filtersApplied.splice(4, 1, {
          key: "periodo",
          label: "Período",
          model: this.periodo
        });
      }
      if (this.grupoSelected) {
        this.filtersApplied.splice(5, 1, {
          key: "grupoSelected",
          label: "Grupo práctica",
          model: this.grupoSelected.value
        });
      }
      if (this.codigo) {
        this.filtersApplied.splice(6, 1, {
          key: "codigo",
          label: "Código de práctica",
          model: this.codigo
        });
      }
    },
    async applyFilters() {
      this.showFilters = false;
      this.isLoading = true;
      this.customizeFiltersApplied();
      const filters = {
        tipoCobId: this.tipoCoberturaSelected,
        osId: this.convenioSelected?.id,
        planId: this.planSelected?.id,
        gruId: this.grupoSelected?.id,
        periodo: this.periodo,
        codigo: this.codigo,
        oriId: this.origenSelected?.id
      };
      try {
        const response = await this.getCoseguroTipoCoberturaEspecial(filters);
        this.itemsCoseguros = response;
        // hago un timeout para que el DOM cargue por completo y pueda recorrer los elementos contenido
        setTimeout(function() {
          // Selecciono a todos los items de la tabla
          const tableRows = document.querySelectorAll(
            ".v-data-table__wrapper table tbody tr td"
          );
          // filtro por los que tienen botones dentro (acciones y de usuarios)
          const arrayRows = Array.from(tableRows).filter(cell =>
            cell.innerHTML.includes("<")
          );
          // Los que son pares son acciones, los que son impares son expandables
          arrayRows.forEach((element, index) => {
            if (index % 2 === 0) element.classList.add("fixed-item-1");
            else element.classList.add("fixed-item-2");
          });
        }, 500);
        this.isLoading = false;
      } catch {
        this.isLoading = false;
      }
    },
    deleteCoseguro(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      this.loadingBtnDelete = true;
      const response = await this.deleteCoseguroTipoCob(this.idToDelete);
      if (response.status === 200) {
        this.showDeleteModal = false;
        this.setAlert({
          type: "success",
          message: "Coseguro eliminado con exito"
        });
        this.loadingBtnDelete = false;
        this.applyFilters();
      }
    },
    resetForm() {
      this.$refs["filters-form"].reset();
    },
    openModalEditCoseguro(item) {
      this.itemCoseguro = item;
      this.openCosegurosEdit = true;
    },
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    },
    closeAndReload() {
      this.openCosegurosEdit = false;
      this.applyFilters();
    }
  }
};
</script>
<style>
.fixed-item-1 {
  position: sticky;
  right: 2.1%;
  background-color: white;
}
.fixed-item-2 {
  position: sticky;
  right: 0;
  background-color: white;
  padding-left: 0;
}
</style>
