<template>
  <v-card>
    <v-card-title>
      <span class="pl-1 primary--text">{{ formEditTitle }}</span>
    </v-card-title>
    <v-card-text>
      <v-form
        v-model="isFormValid"
        ref="form"
        form="form"
        id="form"
        @submit.prevent="saveEdit()"
      >
        <v-row>
          <!-- convenios -->
          <v-col cols="4" class="py-0">
            <v-autocomplete
              v-model="convenioSelected"
              :items="itemsConvenio"
              label="Convenio"
              return-object
              item-text="value"
              item-value="id"
              :rules="rules.required"
              @change="setPlanesByConvenios()"
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <!-- planes -->
          <v-col cols="4" class="py-0">
            <v-autocomplete
              v-model="planSelected"
              :items="itemsPlanes"
              :loading="loadingPlanesByConvenio"
              label="Plan"
              return-object
              item-text="value"
              item-value="id"
              :rules="rules.required"
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
          <!-- origen -->
          <v-col cols="4" class="py-0">
            <v-autocomplete
              v-model="origenSelected"
              :items="itemsOrigen"
              label="Origen"
              return-object
              :rules="rules.required"
              item-text="value"
              item-value="id"
              outlined
              dense
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="py-0">
            <v-radio-group
              @change="changeSwitchs()"
              row
              v-model="grupoAndRango"
              mandatory
              class="py-0 mt-2"
            >
              <v-radio
                class="py-0"
                label="Grupo de prácticas"
                value="G"
              ></v-radio>
              <v-radio
                class="py-0"
                label="Rango de códigos"
                value="R"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="grupoAndRango === 'G'">
          <v-col cols="12" md="12" sm="12" class="py-0">
            <v-card-title class="pl-0 pt-0"
              ><b>Grupo de prácticas</b></v-card-title
            >
          </v-col>
        </v-row>
        <v-row v-if="grupoAndRango === 'G'">
          <!-- grupo practica -->
          <v-col cols="5" class="py-0 pt-0">
            <v-autocomplete
              v-model="grupoPracticaSelected"
              :items="itemsGrupoPractica"
              label="Grupo de prácticas"
              return-object
              item-text="value"
              item-value="id"
              :rules="grupoAndRango === 'G' ? rules.required : []"
              outlined
              clearable
              dense
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="grupoAndRango === 'R'">
          <v-col cols="12" md="12" sm="12" class="py-0">
            <v-card-title class="pl-0 pt-0"
              ><b>Rango de códigos</b></v-card-title
            >
          </v-col>
        </v-row>
        <section v-if="grupoAndRango === 'R'">
          <v-row class="pt-0">
            <!-- nomenclador -->
            <v-col cols="4" class="py-0">
              <v-autocomplete
                v-model="tipoNomenclador"
                :items="itemsTipoNomenclador"
                label="Tipo nomenclador"
                return-object
                item-text="value"
                item-value="id"
                outlined
                clearable
                @change="changeNomenclador"
                dense
                :rules="grupoAndRango === 'R' ? rules.required : []"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <!-- codigo desde -->
            <v-col cols="3" class="py-0">
              <v-text-field
                v-model.trim="codigoDesde"
                label="Código desde"
                outlined
                clearable
                dense
                v-mask="'#########'"
                :rules="
                  grupoAndRango === 'R' &&
                  ((codigoDesde != null && codigoDesde != '') ||
                    (codigoHasta != null && codigoHasta != ''))
                    ? rules.requiredAcceptZero.concat(
                        rules.positiveNumber,
                        parseInt(codigoDesde) <= parseInt(codigoHasta) ||
                          'Rango inválido'
                      )
                    : []
                "
                :disabled="tipoNomenclador == null"
                @change="buscarCodigos(true)"
              ></v-text-field>
            </v-col>
            <!-- Descripcion codigo desde -->
            <v-col cols="5" class="py-0" md="5">
              <v-text-field
                v-model.trim="descCodigoDesde"
                type="text"
                outlined
                dense
                clearable
                disabled
                label="Descripción código desde"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Boton buscar codigo desde -->
            <v-col cols="1" class="py-0">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    absolute
                    fab
                    small
                    color="primary"
                    :disabled="tipoNomenclador == null"
                    @click="openModalBusquedaCodigos(true)"
                  >
                    <v-icon>{{ searchIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Buscar "código desde" a través de la descripción</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row>
            <!-- codigo hasta -->
            <v-col cols="3" class="py-0">
              <v-text-field
                v-model.trim="codigoHasta"
                label="Código hasta"
                outlined
                clearable
                v-mask="'#########'"
                dense
                :disabled="tipoNomenclador == null"
                :rules="
                  grupoAndRango === 'R'
                    ? rules.requiredAcceptZero.concat(
                        rules.positiveNumber,
                        Number(codigoDesde) <= Number(codigoHasta) ||
                          'Rango inválido'
                      )
                    : []
                "
                @change="buscarCodigos(false)"
              ></v-text-field>
            </v-col>
            <!-- Descripcion codigo hasta -->
            <v-col cols="5" class="py-0" md="5">
              <v-text-field
                v-model.trim="descCodigoHasta"
                type="text"
                outlined
                dense
                clearable
                disabled
                label="Descripción código hasta"
                autocomplete="off"
              ></v-text-field>
            </v-col>
            <!-- Boton buscar codigo hasta -->
            <v-col cols="1" class="py-0">
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-on="on"
                    v-bind="attrs"
                    absolute
                    fab
                    small
                    color="primary"
                    :disabled="tipoNomenclador == null"
                    @click="openModalBusquedaCodigos(false)"
                  >
                    <v-icon>{{ searchIcon }}</v-icon>
                  </v-btn>
                </template>
                <span>Buscar "código hasta" a través de la descripción</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </section>
        <v-row>
          <v-col cols="12" md="12" sm="12" class="py-0">
            <v-card-title class="pl-0 pt-0"
              ><b>Configuración de coseguro</b></v-card-title
            >
          </v-col>
        </v-row>
        <v-row>
          <!-- area -->
          <v-col cols="3" md="3" class="py-0">
            <v-autocomplete
              v-model="areaSelected"
              :items="itemsArea"
              label="Área"
              item-text="value"
              item-value="id"
              outlined
              clearable
              :rules="rules.required"
              dense
            ></v-autocomplete>
          </v-col>
          <!-- nivel aplicacion -->
          <v-col cols="3" md="3" class="py-0">
            <v-autocomplete
              v-model="nivelAplicacionSelected"
              :items="itemsNivelAp"
              label="Nivel aplicación"
              item-text="value"
              item-value="id"
              outlined
              @change="changeCombos()"
              :rules="rules.required"
              dense
              clearable
            ></v-autocomplete>
          </v-col>
          <!-- modo valor (operador) -->
          <v-col cols="4" class="py-0">
            <v-autocomplete
              :disabled="
                nivelAplicacionSelected
                  ? nivelAplicacionSelected === 'B'
                  : false
              "
              v-model="modoSelected"
              :items="itemsModo"
              label="Modo de valor"
              item-text="value"
              item-value="id"
              outlined
              clearable
              :rules="rules.required"
              dense
            ></v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <!-- valor -->
          <v-col cols="3" class="py-0">
            <currency-input
              v-model="valor"
              label="Valor"
              :options="currencyOptions"
              :rules="
                modoSelected && modoSelected === 'P'
                  ? rules.required.concat(valor <= 100 || 'Numero mayor a 100')
                  : rules.required
              "
            ></currency-input>
          </v-col>
          <!-- cant. determinaciones -->
          <v-col cols="3" md="3" class="py-0">
            <v-text-field
              dense
              outlined
              :disabled="
                nivelAplicacionSelected
                  ? nivelAplicacionSelected === 'P'
                  : false
              "
              :rules="rules.required"
              v-model="cantDeterminaciones"
              label="Cant. determinaciones"
              persistent-hint
              type="text"
              v-mask="'###'"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="showImporteAndSwitch">
          <v-col cols="4" class="py-0">
            <v-switch
              class="mt-1"
              v-model="importeAdicPorCodigo"
              label="Importe adicional por código"
            ></v-switch>
          </v-col>
          <!-- importe -->
          <v-col v-if="importeAdicPorCodigo" cols="3" md="3" class="py-0">
            <currency-input
              v-model="importe"
              label="Importe"
              :options="currencyOptions"
              :rules="importe === 0 ? [] : rules.required"
            ></currency-input>
          </v-col>
        </v-row>
        <v-row>
          <!-- periodo desde -->
          <v-col cols="3" class="py-0">
            <v-text-field
              dense
              outlined
              v-model="periodoDesde"
              label="Período desde"
              hint="Formato AAAAMM"
              persistent-hint
              type="text"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
              v-mask="'######'"
              :rules="
                rules.required.concat(
                  [rules.validDateRange(periodoDesde, periodoHasta)],
                  rules.periodoYyyyMm
                )
              "
            ></v-text-field>
          </v-col>
          <!-- periodo hasta -->
          <v-col cols="3" class="py-0">
            <v-text-field
              dense
              outlined
              v-model="periodoHasta"
              label="Período hasta"
              type="text"
              hint="Formato AAAAMM"
              onkeypress="return (event.charCode >= 48 && event.charCode <= 57)"
              persistent-hint
              v-mask="'######'"
              :rules="
                rules.required.concat(
                  [rules.validDateRange(periodoDesde, periodoHasta)],
                  rules.periodoYyyyMm
                )
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn outlined @click="closeModal"> Cancelar </v-btn>
      <v-btn
        type="submit"
        :disabled="!isFormValid"
        :loading="loadingSaveBtn"
        form="form"
        color="primary"
      >
        Guardar
      </v-btn>
    </v-card-actions>
    <!-- modal para buscar codigo desde -->
    <v-dialog
      v-model="modalBusquedaCodigos"
      v-if="modalBusquedaCodigos"
      @keydown.esc="toggleModalBusquedaCodigos"
      max-width="50%"
      persistent
    >
      <BusquedaCodigoNomenclador
        @toggleModalBusquedaCodigo="toggleModalBusquedaCodigos"
        @findCodigo="findCodigo"
        :nomencladorObject="tipoNomenclador"
      ></BusquedaCodigoNomenclador>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import { mask } from "vue-the-mask";
import CurrencyInput from "@/components/shared/CurrencyInput.vue";
import enums from "@/utils/enums/index.js";
import BusquedaCodigoNomenclador from "@/components/modules/afiliaciones/afiliados/BusquedaCodigoNomenclador.vue";

export default {
  name: "EditCosegurosTipoCoberturaEspecial",
  props: {
    itemCoseguro: {
      type: Object,
      required: false,
      default: null
    },
    idTipoCob: {
      type: Number,
      required: true
    }
  },
  components: {
    CurrencyInput,
    BusquedaCodigoNomenclador
  },
  directives: { mask },
  data: vm => ({
    rules: rules,
    searchIcon: enums.icons.SEARCH,
    formEditTitle: "Nuevo coseguro",
    isFormValid: false,
    loadingSaveBtn: false,
    loadingPlanesByConvenio: false,
    itemsOrigen: [],
    origenSelected: null,
    itemsPlanes: [],
    planSelected: null,
    itemsConvenio: [],
    convenioSelected: null,
    codigoDesde: null,
    codigoHasta: null,
    grupoPracticaSelected: null,
    itemsGrupoPractica: [],
    tipoNomenclador: null,
    modalBusquedaCodigos: false,
    itemsTipoNomenclador: [],
    descCodigoHasta: null,
    descCodigoDesde: null,
    isFindCodigoDesde: false,
    importeAdicPorCodigo: false,
    cantDeterminaciones: null,
    valor: 0,
    modoSelected: null,
    nivelAplicacionSelected: null,
    areaSelected: null,
    importe: 0,
    periodoDesde: null,
    periodoHasta: null,
    showImporteAndSwitch: true,
    currencyOptions: {
      locale: "nl-NL",
      currency: "USD",
      currencyDisplay: "narrowSymbol",
      valueRange: {
        min: 0,
        max: 999999999999999
      },
      precision: undefined,
      hideCurrencySymbolOnFocus: true,
      hideGroupingSeparatorOnFocus: true,
      hideNegligibleDecimalDigitsOnFocus: true,
      autoDecimalDigits: false,
      exportValueAsInteger: false,
      autoSign: true,
      useGrouping: true
    },
    grupoAndRango: null,
    itemsArea: [
      { id: "A", value: "Ambulatorio" },
      { id: "I", value: "Internado" }
    ],
    itemsNivelAp: [
      { id: "B", value: "Nivel de Bono" },
      { id: "P", value: "Nivel de Práctica" }
    ],
    itemsModo: [
      { id: "I", value: "Importe Fijo" },
      { id: "P", value: "Porc. valor Práctica" }
    ]
  }),
  created() {
    if (this.itemCoseguro != null) {
      this.formEditTitle = "Editar coseguro";
      this.changeCombos();
      this.setCoseguros(this.itemCoseguro);
    }
    this.setSelects();
  },
  methods: {
    ...mapActions({
      getPlanesByConvenio: "configAfiliaciones/getPlanesByConvenio",
      getPlanesTaxonomy: "configAfiliaciones/getPlanesTaxonomy",
      getConvenios: "afiliaciones/getConvenios",
      fetchTiposCoberturas: "afiliaciones/fetchTiposCoberturas",
      fetchOrigenes: "configAppBenef/fetchOrigenes",
      getNomencladores: "afiliaciones/getNomencladores",
      saveCoseguroTipoCoberturaEspecial:
        "afiliaciones/saveCoseguroTipoCoberturaEspecial",
      getGrupos: "afiliaciones/getGrupos",
      getDescByNomencladorYCodigo: "afiliaciones/getDescByNomencladorYCodigo",
      setAlert: "user/setAlert"
    }),
    async setSelects() {
      // id = 1 -> corresponde a coseguros
      const gruposPractica = await this.getGrupos(1);
      this.itemsGrupoPractica = gruposPractica;
      const tipoCoberturas = await this.fetchTiposCoberturas();
      this.itemsTipoCobertura = tipoCoberturas;
      const convenios = await this.getConvenios();
      this.itemsConvenio = convenios;
      this.itemsConvenio.unshift({
        id: -1,
        value: "Todos"
      });
      const origenes = await this.fetchOrigenes();
      this.itemsOrigen = origenes;
      this.itemsOrigen.unshift({
        id: 0,
        value: "Todos"
      });
      const tipoNomenclador = await this.getNomencladores();
      this.itemsTipoNomenclador = tipoNomenclador;
    },
    setCoseguros(item) {
      if (item.grupo.id === 0) this.grupoAndRango = "R";
      else if (item.nomenclador.value === null) this.grupoAndRango = "G";
      if (item.operador === "P") this.showImporteAndSwitch = false;
      this.convenioSelected = item.os;
      this.setPlanesByConvenios();
      this.planSelected = item.plan;
      this.origenSelected = item.ori;
      this.grupoPracticaSelected =
        item.grupo?.value === null ? null : item.grupo;
      this.tipoNomenclador =
        item.nomenclador?.value === null ? null : item.nomenclador;
      this.codigoDesde = item.nomencladorCodigoDesde;
      this.descCodigoDesde = item.nombreDesde;
      this.codigoHasta = item.nomencladorCodigoHasta;
      this.descCodigoHasta = item.nombreHasta;
      this.areaSelected = item.area;
      this.nivelAplicacionSelected = item.operador;
      this.modoSelected = item.modo;
      this.valor = item.valor;
      this.cantDeterminaciones = item.cantidad;
      this.importeAdicPorCodigo = item.usarCodigoAdicional;
      this.importe = item.valorCodigoAdicional;
      this.periodoDesde = item.periodoDesde;
      this.periodoHasta = item.periodoHasta;
    },
    async setPlanesByConvenios() {
      this.planSelected = null;
      if (this.convenioSelected === null || this.convenioSelected.id == -1) {
        this.itemsPlanes = [];
        const allPlanes = await this.getPlanesTaxonomy();
        this.itemsPlanes = allPlanes;
        this.itemsPlanes.unshift({
          id: -1,
          value: "Todos"
        });
      } else {
        this.itemsPlanes = [];
        this.loadingPlanesByConvenio = true;
        const planes = await this.getPlanesByConvenio([
          this.convenioSelected.id
        ]);
        this.itemsPlanes = planes;
        this.itemsPlanes.unshift({
          id: -1,
          value: "Todos"
        });
        this.loadingPlanesByConvenio = false;
        // // se verifica que cuando se cambie el convenio, el plan seleccionado exista en los items para
        // // ese convenio, convierte en null en caso de que no exista.
        // const planEncontrado = this.itemsPlanes.find(
        //   x => x.id == this.planSelected?.id
        // );
        // if (planEncontrado === undefined) this.planSelected = null;
      }
    },
    async saveEdit() {
      this.loadingSaveBtn = true;
      const data = {
        tipoCobCoseId: this.itemCoseguro?.tipoCobCoseId,
        idTipoCob: this.idTipoCob,
        osId: this.convenioSelected.id,
        planId: this.planSelected.id,
        gruId: this.grupoAndRango === "G" ? this.grupoPracticaSelected.id : 0,
        area: this.areaSelected,
        periodoDesde: this.periodoDesde,
        periodoHasta: this.periodoHasta,
        modo: this.modoSelected,
        operador: this.nivelAplicacionSelected,
        valor: this.valor,
        cantidad: this.cantDeterminaciones,
        usarCodigoAdicional: this.showImporteAndSwitch
          ? this.importeAdicPorCodigo
          : false,
        valorCodigoAdicional: this.showImporteAndSwitch ? this.importe : 0,
        nomencladorId:
          this.tipoNomenclador == undefined || this.tipoNomenclador == null
            ? null
            : this.tipoNomenclador.id,
        nomencladorCodigoDesde: this.codigoDesde,
        nomencladorCodigoHasta: this.codigoHasta,
        nombreDesde: this.descCodigoDesde,
        nombreHasta: this.descCodigoHasta,
        oriId: this.origenSelected?.id
      };
      try {
        const response = await this.saveCoseguroTipoCoberturaEspecial(data);
        if (response.status === 200) {
          this.setAlert({ type: "success", message: "Guardado con éxito." });
          this.loadingSaveBtn = false;
          this.closeModal();
        }
      } catch (error) {
        this.loadingSaveBtn = false;
      }
    },
    async buscarCodigos(isCodigoDesde) {
      if (isCodigoDesde) {
        if (this.codigoDesde) {
          const data = {
            nomenclador: this.tipoNomenclador.id,
            codigo: this.codigoDesde
          };
          const descripcionCodigoDesde = await this.getDescByNomencladorYCodigo(
            data
          );
          this.descCodigoDesde = descripcionCodigoDesde.nombre;
        } else {
          this.descCodigoDesde = null;
        }
      }
      if (!isCodigoDesde) {
        if (this.codigoHasta) {
          const data = {
            nomenclador: this.tipoNomenclador.id,
            codigo: this.codigoHasta
          };
          const descripcionCodigoHasta = await this.getDescByNomencladorYCodigo(
            data
          );
          this.descCodigoHasta = descripcionCodigoHasta.nombre;
        } else {
          this.descCodigoHasta = null;
        }
      }
    },
    toggleModalBusquedaCodigos() {
      this.modalBusquedaCodigos = !this.modalBusquedaCodigos;
    },
    openModalBusquedaCodigos(isCodigoDesde) {
      if (isCodigoDesde) this.isFindCodigoDesde = true;
      else this.isFindCodigoDesde = false;
      this.modalBusquedaCodigos = true;
    },
    findCodigo(codigoSelected) {
      if (this.isFindCodigoDesde) {
        this.codigoDesde = codigoSelected.nomCod;
        this.descCodigoDesde = codigoSelected.nombre;
      } else {
        this.codigoHasta = codigoSelected.nomCod;
        this.descCodigoHasta = codigoSelected.nombre;
      }
    },
    changeSwitchs() {
      if (this.grupoAndRango === "R") this.grupoPracticaSelected = null;
      if (this.grupoAndRango === "G") {
        this.tipoNomenclador = null;
        this.codigoDesde = null;
        this.codigoHasta = null;
        this.descCodigoDesde = null;
        this.descCodigoHasta = null;
      }
    },
    changeCombos() {
      switch (true) {
        case this.nivelAplicacionSelected === "B":
          this.showImporteAndSwitch = true;
          this.modoSelected = "I";
          break;
        case this.nivelAplicacionSelected === "P":
          this.showImporteAndSwitch = false;
          this.modoSelected = "I";
          this.cantDeterminaciones = 1;
          break;
        default:
          break;
      }
    },
    changeNomenclador() {
      this.codigoDesde = null;
      this.codigoHasta = null;
      this.descCodigoDesde = null;
      this.descCodigoHasta = null;
    },
    closeModal() {
      this.$emit("closeAndReload");
    }
  }
};
</script>
